<template>
  <div class="form-layout">
    <div class="form-layout__image"></div>
    <div class="form form-layout__body">
      <div class="text-center mb-48 p-relative">
        <img src="../assets/img_logo.png" alt="Tokoin Logo" class="block-center" height="46" />
        <p class="headline-md mt-12">{{ subText }}</p>

        <alert alertClass="alert--unique" />
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Alert'

export default {
  name: 'FormLayout',

  props: {
    subText: {
      type: String,
      required: true
    }
  },

  components: {
    Alert
  }
}
</script>
